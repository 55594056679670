<template lang="pug">
v-container(fluid)
    v-row
        v-img( cover :src='arbitraje_encabezado.imagen'  height='76vh').d-none.d-md-flex
        
            v-row.fill-height.fill-width.justify-center.align-center
                v-col(cols=12 md=3  style='background-color: rgba(0, 0, 0, .3); margin-bottom: 1em').d-none.d-md-flex.justify-center.align-center
                    h1.font-weight-bold.pb-2.text-uppercase.white--text(v-html='Eventos.encabezado.título').justify-center.text-center
        v-img(  :src='arbitraje_encabezado.imagen' height='300px' width='100%').d-md-none
            v-container.d-md-none
                v-row(  justify='center')
                    v-col( align='center' cols=6 style='background-color: rgba(0, 0, 0, .3);margin-top: 5em ').d-md-none
                        h2.font-weight-bold.pb-2.text-uppercase.white--text(v-html='Eventos.encabezado.título').justify-center
        
    v-row.white.justify-center.align-center
        v-col(cols=10 md=7 style="margin-bottom: 3em; margin-top: 3em")
            h1.pb-2.text-uppercase.azul--text(v-html='Eventos.encabezado.título').justify-center.text-center.d-none.d-md-block
            h2.text-uppercase.azul--text(v-html='Eventos.encabezado.título').justify-center.text-center.d-md-none

    v-row.justify-center.align-center(cols=12 md=7 style="margin-bottom: 3em;")
        hr.primary.hr
    v-container.justify-center.align-center.mb-12
        .row(style="display: flex; justify-content: center; align-items: center").mb-12
            v-col(cols=11 md=3 v-for='blog, i of registroDeEventos' :key='i').mr-4.ml-4.mt-4
                v-row.justify-center.align-center
                    v-card.azul(width='430' height='500')
                        v-img(:src='blog.portada' height='190' width='100%')
                        p(v-html='descripcionCorta(blog.contenido)' style="font-size: 13px").pa-4.mt-3.white--text.text-justify.d-none.d-md-block
                        p(v-html='descripcionCorta(blog.contenido)' style='font-size: 14px').pa-4.mt-3.white--text.text-justify.d-md-none


                        v-row(style="margin-top: 2%").justify-center.align-center
                            v-btn(outlined color='secondary' @click='dirigirA(blog)') Leer Más 
                v-row.justify-center.align-center.mt-5
                    //- h2.secondary--text.text-center {{ blog.título }}
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';


export default {
    methods: {
        descripcionCorta(descripcion){
            return `${descripcion.substring(0, 300)}...`
        },
        ...mapMutations({
            setItem: 'Blog/setItem',
        }),
        dirigirA(item){
            this.setItem(item)
            this.$router.push({name: 'entradaBlog', params: {idEvento: item.id}})
        }
    },
    computed: {
        Eventos(){
            return this.Contenido.eventos
        },
        ...mapGetters({
            registro: 'Blog/registro',
        }),
        registroDeEventos(){
           return this.registro.map((item)=>({...item, etiquetas: item.etiquetas.map(({id})=>id)}))
                        .filter(({etiquetas})=>etiquetas.some((idEtiqueta)=>idEtiqueta === 'EO8qvkRv7Rv2kgu9r0vA'))
        }
    },
    created(){
        this.$store.dispatch('Blog/obtener_todos')
    },
}

</script>
<style lang="sass" scoped>
.hr
    width: 90%
    height: 5px
    margin: 0px!important
    opacity: 1!important
    border: 0px
</style>